<template>
  <b-container class="w-100 p-0">
    <b-row no-gutters>
      <b-card no-body class="col-12">
        <b-card-body class="p-0">
          <template v-if="isFilterMode">
            <b-button block variant="outline-primary" v-b-toggle.filter-collapse>
              <span class="when-open">
                <b-icon icon="caret-up-fill" font-scale="0.9"></b-icon>
              </span>
              <span class="when-closed">
                <b-icon icon="caret-down-fill" font-scale="0.9"></b-icon>
              </span>
              Show Filters
            </b-button>
            <b-collapse id="filter-collapse" class="mb-1 mt-1">
              <b-card>
                <template v-if="isFilterMode">
                  <template v-for="(field, index) in mobileFields">
                    <b-row :key="'row_' + field.key" class="mb-1">
                      <b-col cols="9">
                        <b-form-select style="text-align: center;"
                          :value="field.key"
                          :options="columns"
                          value-field="key"
                          text-field="DisplayName"
                          size="sm"
                          @input="setMobileFieldKey({ value: $event, index })"
                          >
                          <template v-slot:first>
                            <b-form-select-option value="" disabled>Choose field</b-form-select-option>
                          </template>
                        </b-form-select>
                      </b-col>
                      <b-col cols="3">
                        <b-dropdown no-flip size="sm" no-caret right
                          :key="'fieldselect_' + field.key"
                          :variant="isFilterMode ? 'outline-secondary' : 'none'"
                          split-variant="outline-primary"
                          v-if="field.column && field.column.SectionTableLayoutColumnId > 0"
                          :title="field.column.DisplayDescription && field.column.DisplayDescription.length > 0 ? field.column.DisplayDescription : field.column.DisplayName"
                          boundary="window"
                          block
                          class="tpi-disabled"
                          :disabled="!isFilterMode"
                          menu-class="w-100 tpi-dropdown"
                        >
                          <template v-slot:button-content>
                            <b-button title="Click Column to Sort and Filter" variant="outline-secondary" size="sm" flush
                              style="border: 0; padding: 0"
                              v-if="isFilterMode && (field.column.SortBy || field.column.FilterBy || field.column.KeywordSearchBy)"
                            >
                              <!-- <span class="text-black">{{field.column.DisplayName}}</span> -->
                              <b-badge pill variant="secondary"
                                title="Filter Count"
                                v-if="isFiltered(field.column)"
                              >
                                {{selectedFilterCount(field.column)}}
                              </b-badge>
                              <b-badge pill variant="secondary"
                                title="Sort"
                                v-if="field.column.sort != 0"
                              >
                                <b-icon icon="arrow-up" v-if="field.column.sort == -1" aria-hidden="true"></b-icon>
                                <b-icon icon="arrow-down" v-if="field.column.sort == 1" aria-hidden="true"></b-icon>
                              </b-badge>

                            </b-button>
                            <b-icon :icon="isFiltered(field) ? 'funnel-fill' :  'funnel'" aria-hidden="true"
                              v-if="isFilterMode && (field.column.SortBy || field.column.FilterBy || field.column.KeywordSearchBy)"
                            >
                            </b-icon>
                          </template>

                          <template v-if="isFilterMode">
                            <template v-if="(field.column.SortBy || field.column.FilterBy || field.column.KeywordSearchBy)">
                              <b-dropdown-header class="display-description">{{field.column.DisplayDescription}}</b-dropdown-header>
                              <b-dropdown-divider></b-dropdown-divider>
                              <b-dropdown-group v-if="field.column.SortBy">
                                <b-button-toolbar class="col-md-4 text-center">
                                  <b-button-group size="sm">
                                    <b-button title="Z to A" variant="outline-dark"
                                      @click="onSort({ tableLayoutColumn: field.column, sort: -1 })"
                                    >
                                      <b-icon icon="arrow-up"  :variant="field.column.sort == -1 ? 'success':''" aria-hidden="true"></b-icon>
                                    </b-button>
                                    <b-button title="A to Z" variant="outline-dark"
                                      @click="onSort({ tableLayoutColumn: field.column, sort: 1 })"
                                    >
                                      <b-icon icon="arrow-down" :variant="field.column.sort == 1 ? 'success':''" aria-hidden="true"></b-icon>
                                    </b-button>
                                  </b-button-group>
                                </b-button-toolbar>

                              </b-dropdown-group>
                              <template v-if="field.column.FilterBy">
                                <b-dropdown-divider></b-dropdown-divider>
                                <b-dropdown-form class="filter-form">
                                  <template v-for="facet in field.column.facets">
                                    <b-form-checkbox  :key="'facet-' + facet.key"
                                      v-model="facet.selected"
                                      @input="onFacetClick({ tableLayoutColumn: field.column })"
                                      v-if="facet.count > 0"
                                    >
                                      {{facet.displayName != '' ? facet.displayName : '[Blank]'}}
                                      <b-badge pill variant="secondary">{{facet.count}} </b-badge>
                                    </b-form-checkbox>
                                  </template>
                                </b-dropdown-form>
                              </template>
                              <template v-if="field.column.FilterBy">
                                <b-dropdown-divider></b-dropdown-divider>
                                <b-dropdown-item-button @click="clearFilters(field.column)">
                                  <b-icon icon="x" variant="danger" aria-hidden="true"></b-icon>
                                  Clear
                                </b-dropdown-item-button>
                              </template>
                            </template>
                          </template>
                        </b-dropdown>
                      </b-col>
                    </b-row>
                  </template>
                  <b-form-input :id="'input-table-keyword-' + sectionTable.key" size="sm" class="mb-1"
                    v-model="sectionTable.keyword" placeholder="Keyword Search" debounce="250">
                  </b-form-input>
                </template>
              </b-card>
            </b-collapse>
          </template>
          <b-table-simple hover small responsive :tpi-field-count="fields.length" ref="tpitable">
            <template v-for="(item, index) in sectionTable.filteredModels">
              <b-thead head-variant="light" :key="item.PcnCatalogNumber + '_head'">
                <b-tr v-on:click="onRowClicked(item, index, headerFields.find(f => f.key === 'addItemToCart') && sectionTable.HasCart)">
                  <b-th colspan="3" v-on:click="onRowClicked(item, index, false)">
                    <template v-if="headerFields.find(f => f.key === 'FingoodTpiMfgMod' || f.key === 'DivisionModels' || f.key === 'PcnCatalogNumber')">
                      {{ getObjectValue(item, headerFields.find(f => f.key === 'FingoodTpiMfgMod' || f.key === 'DivisionModels' || f.key === 'PcnCatalogNumber')) }}
                    </template>
                  </b-th>
                  <b-th v-on:click="onRowClicked(item, index, false)">
                    <template v-if="headerFields.find(f => f.key === 'TotalList')">
                      {{ getObjectValue(item, headerFields.find(f => f.key === 'TotalList')) }}
                    </template>
                  </b-th>
                  <b-th>
                    <template v-if="headerFields.find(f => f.key === 'addItemToCart') && sectionTable.HasCart">
                      <b-button
                        v-b-modal="'modalQuantity-' + item.PcnCatalogNumber"
                        size="md"
                        variant="info background-color-primary"
                        class="cart-btn"
                        @click="setActiveItem(item)"
                      >
                        <b-icon icon="cart-plus" size="sm" aria-hidden="true"></b-icon>
                      </b-button>
                    </template>
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody :key="item.PcnCatalogNumber + '_body'">
                <b-tr v-on:click="onRowClicked(item, index, false)">
                  <b-td v-for="field in mobileFields" :key="field.key">
                    <span v-if="field.column" class="table-inner-heading">{{ field.column.DisplayName }}</span><br />
                    <span v-if="field.column" class="table-inner-value">
                      <template v-if="field.column.ParentFormatType === formatType.List">
                        <component :is="field.column.ParentFormatValue || 'ul'" v-for="value in getObjectValue(item, field)" :key="value.key" class="text-center">
                          <component :is="field.column.FormatValue">
                            <template v-if="field.column.PropertyType == propertyType.String">{{ value }}</template>
                            <template v-else>{{ getObjectValue(value, field.column) }}</template>
                          </component>
                        </component>
                      </template>
                      <template v-else>
                        {{ getObjectValue(item, field) }}
                      </template>
                    </span>
                  </b-td>
                </b-tr>
              </b-tbody>
            </template>
          </b-table-simple>
        </b-card-body>
      </b-card>
    </b-row>
    <b-modal v-model="showModelDetails" title="Model Details" @close="closeDetails()" ok-only ok-title="Close" @ok="closeDetails()" centered size="xl"> <!--class="detailsTest" v-if="sideBarModel.visible && containsActivePCN"> -->
      <model-details-card v-if="!sideBarModel.model.isNone"
        :model="sideBarModel.model"
        :tableLayout="sideBarModel.tableLayout"
        @closeDetails="closeDetails"
        :hasCart="catalog.CartEnabled && sectionTable.HasCart"
        :sectionTable="sectionTable">
      </model-details-card>
    </b-modal>
    <modal-select-quantity :modalQuantityVisible="modalQuantityVisible" @closeModal="modalQuantityVisible = false"></modal-select-quantity>
  </b-container>
</template>

<script lang="ts">
import _ from 'lodash'

import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { SectionTable, TableLayoutColumn, Model, SideBarItem } from '../store/product/types'
import LoadingText from '@/components/utility/loading-text.vue'
import ModelDetailsCard from '@/components/model-details-card.vue'
import modalSelectQuantity from './cart/modal-cart-select-quantity.vue'

import { BTable } from 'bootstrap-vue'

import MxSite from '@/mixins/site'
import { CartItem } from '@/store/cart/types'
import { blankCartItem } from '@/store/cart/constants'

const nsCart = namespace('cart')

const nsProduct = namespace('product')

@Component({
  components: {
    'loading-text': LoadingText,
    'model-details-card': ModelDetailsCard,
    'modal-select-quantity': modalSelectQuantity,
  },
})
export default class extends Mixins(MxSite) {
  @Prop()
  public readonly sectionTable!: SectionTable

  @nsProduct.Getter('sideBarModel')
  public sideBarModel!: SideBarItem

  @nsProduct.Mutation('updateSort')
  public updateSort: any

  @nsProduct.Action('clearColumnFilters')
  public clearColumnFilters: any

  @nsProduct.Mutation('updateActiveModel')
  public updateActiveModel: any

  @nsProduct.Mutation('setActiveModel')
  public setActiveModel: any

  @nsProduct.Mutation('clearActiveModel')
  public clearActiveModel: any

  @nsProduct.Action('applyFilters')
  public applyFilters!: any

  @nsProduct.Action('applyKeyword')
  public applyKeyword!: any

  @nsProduct.Action('applySorts')
  public applySorts!: any

  @nsCart.Action('setActiveCartItem')
  private setActiveCartItem: any

  public modalQuantityVisible = false

  public setActiveItem(itemObj: any) {
    const cartItem: CartItem = blankCartItem
    // Convert item type to cartItem type
    const item = itemObj
    cartItem.pcnCatalogNumber = item.PcnCatalogNumber || ''
    cartItem.fingoodTpiMfgMod =
      item.FingoodTpiMfgMod ?? item.Details.Description?.toString()
    cartItem.description =
      item.SapModelDescription != null
        ? item.SapModelDescription
        : item.Descriptions && item.Descriptions.length > 0
          ? item?.Descriptions[0]
          : ''
    cartItem.quantity = 0
    cartItem.price = item.TotalList ?? item.price
    cartItem.totalPrice = item.totalPrice ?? cartItem.quantity * cartItem.price
    cartItem.imgThumbnail = item?.Image?.ImagePath || ''
    cartItem.shippingStrategyId = item.ShippingStrategyId
    this.setActiveCartItem({ cartItem }, { item: cartItem })
    this.modalQuantityVisible = true
    this.showModelDetails = true
  }

  public addToCartRef
  public showModelDetails = false

  public mounted() {
    this.addToCartRef = this.$refs.AddToCartRefs as Vue
  }

  public mobileFields: any[] = []
  public showAddToCart = false

  get headerFields() {
    const { sectionTable } = this
    const retFields: any[] = []

    // Set Static Fields
    let field = sectionTable.fields.find(f => f.key === 'FingoodTpiMfgMod' || f.key === 'DivisionModels')
    if (field) {
      retFields.push(field)
    } else {
      field = sectionTable.fields.find(f => f.key === 'PcnCatalogNumber')
      if (field) {
        retFields.push(field)
      }
    }
    field = sectionTable.fields.find(f => f.key === 'TotalList')
    if (field) {
      retFields.push(field)
    }
    field = sectionTable.fields.find(f => f.key === 'addItemToCart')
    if (field) {
      retFields.push(field)
    }

    return retFields
  }

  get fields() {
    const { sectionTable } = this
    return sectionTable.fields.filter(field => field.column.ShowOnMobile)
  }

  get containsActivePCN(): boolean {
    const { sectionTable, sideBarModel } = this
    return sideBarModel.model && sectionTable.Models.some(model => model.PcnCatalogNumber === sideBarModel.model.PcnCatalogNumber)
  }

  get isTableHidden(): boolean {
    const { sideBarModel, containsActivePCN, sectionTable } = this
    return sideBarModel.visible && containsActivePCN && sectionTable.Cols < 12
  }

  get isFilterMode() {
    return this.sectionTable && this.sectionTable.Models && this.sectionTable.Models && !this.sectionTable.HideTableFilters
  }

  get columns() {
    return this.sectionTable.TableLayout.Columns
      .filter(c => c.key !== 'FingoodTpiMfgMod' && c.key !== 'DivisionModels' && c.key !== 'viewDetails' && c.key !== 'TotalList')
      .map(c => _.assign({}, c, { disabled: this.mobileFields.some((f: any) => f.key === c.key) }))
  }

  public created() {
    this.mobileFields = this.fields
  }

  public setMobileFieldKey({ value, index }: { value: string, index: number }) {
    Vue.set(this.mobileFields, index, this.sectionTable.fields.find(f => f.key === value))
  }

  public closeDetails() {
    const params = this.$route.params

    this.clearActiveModel()
    delete params.pcnCatalogNumber

    this.$router.replace({ params })
    this.showModelDetails = false
  }

  public onSort({ tableLayoutColumn, sort }: { tableLayoutColumn: TableLayoutColumn, sort: number }) {
    const { sectionTable } = this
    this.updateSort({ sectionTable, tableLayoutColumn, sort })
    this.applySorts({ sectionTable, tableLayoutColumn, sort })
  }

  public onKeywordInput({ tableLayoutColumn }: { tableLayoutColumn: TableLayoutColumn }) {
    const { sectionTable } = this
    if (sectionTable) {
      this.applyFilters({ sectionTable, tableLayoutColumn })
    }
  }

  public onFacetClick({ tableLayoutColumn }: { tableLayoutColumn: TableLayoutColumn }) {
    const { sectionTable } = this
    if (sectionTable) {
      this.applyFilters({ sectionTable, tableLayoutColumn })
    }
  }

  public selectedFilterCount(tableLayoutColumn: TableLayoutColumn): number {
    return tableLayoutColumn.facets.filter(facet => facet.count > 0 && facet.selected).length +
      (tableLayoutColumn.keyword ? 1 : 0)
  }

  public isFiltered(tableLayoutColumn: TableLayoutColumn): boolean {
    if (!tableLayoutColumn.facets) {
      return false
    }
    return tableLayoutColumn.facets.filter(facet => facet.count > 0 && facet.selected).length > 0 ||
      (!!tableLayoutColumn.keyword)
  }

  public clearFilters(tableLayoutColumn: TableLayoutColumn) {
    const { sectionTable } = this
    this.clearColumnFilters({ sectionTable, tableLayoutColumn })
  }

  public onRowClicked(item: Model, index: number, addableToCart : boolean) {
    if ((this.sectionTable as SectionTable).DetailsPanel && !addableToCart) {
      this.setSelected(index, item)
      this.showModelDetails = true
    }
  }

  public setSelected(index: number, model: Model) {
    const table = (this.sectionTable as SectionTable)
    const tableLayout = table.TableLayout
    const { params } = this.$route

    if (this.sideBarModel.model.PcnCatalogNumber === model.PcnCatalogNumber) {
      this.clearActiveModel()
      delete params.pcnCatalogNumber
    } else {
      this.setActiveModel({ index, model, tableLayout })
      params.pcnCatalogNumber = model.PcnCatalogNumber
    }

    this.$router.replace({ params })
  }

  public sideBarChanged(visible: boolean) {
    if (!visible) {
      this.updateActiveModel({ model: undefined, tableLayout: undefined }) // We just want to ensure nothing is selected
    }
  }

  @Watch('sectionTable.keyword')
  public keywordChanged() {
    const { sectionTable } = this
    if (sectionTable) {
      this.applyKeyword({ sectionTable }) // this way it will recalculate the values in the filters!
    }
  }

  @Watch('sectionTable.filteredModels')
  private sectionTableChanged() {
    const { query } = this.$route
    if (this.site.HasPricing && query.addToCart === '1') {
      const toBeAddedItem = this.sectionTable.filteredModels.find(x => x.PcnCatalogNumber === query.catalogNumber)
      if (toBeAddedItem) {
        this.setActiveItem(toBeAddedItem)
      }
    }
  }

  @Watch('sideBarModel.index')
  public onModelChanged(indexNew: number, indexOld: number) {
    if (indexOld !== indexNew) {
      const bTable = (this.$refs.bSectiontable as unknown as BTable)
      if (bTable) {
        bTable.selectRow(indexNew)
      }
    }
  }
}
</script>

<style scoped>
h3 {
  line-height: 1.5;
  font-size: 20px;
}

ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

li {
  text-align: left;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}

.tpi-dropdown {
  min-width: 250px!important;
}

.filter-form {
  max-height: 200px;
  overflow: auto;
  /* min-width: 200px; */
}

.text-black {
  color: black;
}

.tpi-disabled button {
  opacity: 1!important;
}

.product-mobile .table-inner-heading {
    font-size: .8rem;
    font-weight: 700;
}

@media (max-width:380px) {
  .product-mobile .table-inner-heading {
      font-size: .6rem;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 1;
      display: inline-block;
  }

}

table[tpi-field-count="4"] tbody td {
    width:25%;
}
table[tpi-field-count="3"] tbody td {
    width:33.333%;
}
table[tpi-field-count="2"] tbody td {
    width:50%;
}
table[tpi-field-count="1"] tbody td {
    width:100%;
}
</style>
